<template>
  <div class="container">
    <div class="row mt-4">
      <div class="col-md-12 text-center">
        <h1>{{ result.title }}</h1>
      </div>
      <div class="col-md-4"></div>
      <div class="col-md-4 text-center mt-4">
        <div class="form-group">
          <p for="username">{{ result.subtitle }}</p>
        </div>
        <div class="input-group mt-4" v-if="result.username">
          <input
            @click.prevent="copyToClickboard(result.username)"
            :value="result.username"
            type="text"
            class="form-control my-icon form-input"
          />
        </div>
        <div class="form-group mt-5">
          <button
            class="btn btn-primary continue-btn" @click.prevent="redirectToLogin">Back to sign In</button>
        </div>
      </div>
      <div class="col-md-4"></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Toaster from '../../../../../lib/toaster'
import { copyText } from "vue3-clipboard";
const AuthenticationModule = createNamespacedHelpers("authentication");

export default {
  name: "AccountLookupResult",
  data() {
    return {
      timeoutId: null,
    };
  },
  computed: {
    ...AuthenticationModule.mapGetters(["selectHttpResponse"]),

    result() {
      let title = "Oops...no record found!";
      let subtitle = `We couldn't find any record by that given information, 
      try capitalizing the first character of your first and last name.`;
      let username = "";

      if (this.response) {
        if (this.response.data.result && this.response.data.result.length) {
          title = "Woo-hoo!!! Record Found";
          username = this.response.data.result;
          subtitle = `Great news, your username has been found! click text field to copy`;
        }
      }

      return {
        title,
        subtitle,
        username,
      };
    },
    response() {
      return this.selectHttpResponse;
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push({ name: "Login" });
    },
    copyToClickboard(text) {
      copyText(text, undefined, (error, event) => {
        if (error) {
          Toaster.error({
            message: 'Unable to copy',
            duration: 3000,
            position: 'top'
          })
        } else {
          Toaster.success({
            message: 'Copied to clipboard',
            duration: 3000,
            position: 'top'
          })
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
  .form-input {
    height: 55px;
    border-radius: 10px;
    font-size: 24px;
  }
  .form-input:hover {
    background-color: #c8f4d7;
  }

  .append-input {
    height: 55px;
  }
  
  .my-icon {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("https://use.fontawesome.com/releases/v5.8.2/svgs/regular/copy.svg");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
</style>
