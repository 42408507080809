<template>
  <div class="row mt-4">
    <div class="col-md-12 text-center">
      <h1>Enter the information below to find your account.</h1>
    </div>
    <div class="col-md-4"></div>
    <div class="col-md-4 text-center mt-4">
      <div class="form-group">
        <input
          v-model="form.firstName"
          class="form-control mt-3 form-input"
          placeholder="First name"
          type="text"
          v-on:keyup.enter="lookupAccount"
        />
      </div>
      <div class="form-group">
        <input
          v-model="form.lastName"
          class="form-control mt-3 form-input"
          placeholder="Last name"
          type="text"
          v-on:keyup.enter="lookupAccount"
        />
      </div>
      <div class="form-group">
        <input
          v-model="form.emailAddress"
          class="form-control mt-3 form-input"
          placeholder="Email address"
          type="text"
          v-on:keyup.enter="lookupAccount"
        />
      </div>
      <small v-if="errorMessage" class="text-danger">{{ errorMessage }} </small>
      <div class="form-group mt-5">
        <button class="btn btn-primary continue-btn" @click.prevent="lookupAccount">Continue</button>
      </div>
    </div>
    <div class="col-md-4"></div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { isValidEmailAddress } from '../../../../../lib/utility.functions'
const LoaderModule = createNamespacedHelpers('loaders')
const AuthenticationModule = createNamespacedHelpers('authentication')
const PasswordRecoveryModule = createNamespacedHelpers('passwordRecovery')

export default {
  name: "AccountLookup",
  data(){
    return {
      form: {
        lastName: null,
        firstName: null,
        emailAddress: null,
        errorMessage: null,
      }
    }
  },
  computed: {
    ...AuthenticationModule.mapGetters(['selectHttpResponse']),

    errorMessage(){
      return this.form.errorMessage
    }
  },
  methods: {
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
    ...AuthenticationModule.mapActions(['findUserAccount']),
    ...PasswordRecoveryModule.mapActions(['setCurrentLookupStep']),

    async lookupAccount(){
      const isValidEmail = isValidEmailAddress(this.form.emailAddress)
      console.log({isValidEmail})
      if(isValidEmail){
        this.form.errorMessage = null

        // add to status loads
        this.addToLoaders('account-lookup')
        
        await this.findUserAccount({
          email: this.form.emailAddress,
          lastName: this.form.lastName,
          firstName: this.form.firstName,
        })

        // remove from status loads
        this.removeFromLoaders('account-lookup')

        const response = this.selectHttpResponse

        if(response && response.data.success){
          this.setCurrentLookupStep('account-lookup-results')
        }
      }
      else {
        this.form.errorMessage = 'Please provide a valid email address.'
      }
    },
  }
};
</script>

<style scoped lang="scss">
  .form-input {
    height: 55px;
    border-radius: 10px;
  }
  .continue-btn{
    width: 120px;
  }
</style>

