<template>
  <ion-page>
    <ion-header>
      <ion-toolbar></ion-toolbar>
    </ion-header>
    <ion-content>
      <div class="container">
        <AccountLookup 
        v-if="step === 'account-lookup'"/>

        <AccountLookupResult
        v-if="step === 'account-lookup-results'"/>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { IonPage, IonContent, IonToolbar, IonHeader } from '@ionic/vue'
import { isValidEmailAddress } from '../../../../../lib/utility.functions'
import AccountLookup from './AccountLookup.vue'
import AccountLookupResult from './AccountLookupResult.vue'
const LoaderModule = createNamespacedHelpers('loaders')
const AuthenticationModule = createNamespacedHelpers('authentication')
const PasswordRecoveryModule = createNamespacedHelpers('passwordRecovery')

export default {
  name: "AccountLookupBase",
  components: {
    IonPage,
    IonHeader,  
    IonContent, 
    IonToolbar, 
    AccountLookup, 
    AccountLookupResult
  },
  computed: {
    ...AuthenticationModule.mapGetters(['selectHttpResponse']),
    ...PasswordRecoveryModule.mapGetters(['selectcurrentLookupStep']),

    step(){
      return this.selectcurrentLookupStep
    },
    errorMessage(){
      return this.form.errorMessage
    }
  },
  methods: {
    ...LoaderModule.mapActions(['addToLoaders', 'removeFromLoaders']),
    ...AuthenticationModule.mapActions(['findUserAccount']),
    ...PasswordRecoveryModule.mapActions(['setCurrentStep']),

    async lookupAccount(){

    },
    async validateUserInputs(){
      const isValidEmail = isValidEmailAddress(this.form.emailAddress)

      if(isValidEmail){
        this.form.errorMessage = null

        // add to status loads
        this.addToLoaders('account-lookup')
        
        await this.findUserAccount({
          email: this.form.emailAddress,
          lastName: this.form.lastName,
          firstName: this.form.firstName,
        })

        const response = this.selectHttpResponse

        if(response && response.data.success){
          this.setCurrentStep('account-lookup-results')
        }
        // remove from status loads
        this.removeFromLoaders('account-lookup')
      }
      else {
        this.form.errorMessage = 'Please provide a valid email address.'
      }
    }
  }
};
</script>

<style scoped lang="scss">

</style>
