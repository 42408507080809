/**
 * convert binary to base64 string
 * using to for image conversion
 */
 export const toBase64 = (data) => {
    try{
        const arr = new Uint8Array(data)
        return btoa(
            arr.reduce((data, byte) => data + String.fromCharCode(byte), '')
        )
    }catch(err){
        console.log(err)
    }
}

/**
 * convert binary to base64 string
 * using to for image conversion
 */
export const fromBase64ToBinary = (data) => {
    try{
        return atob(data)
    }catch(err){
        console.log(err)
    }
}

/**
 * valid email address
 * @param {STRING} address email address to be validated
 * @returns {BOOLEAN} true if email address is valid
 */
export const isValidEmailAddress = (address) => {
    if(!address || !address.length){
        return  false
    }

    let isValid = false
    const  pattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)/;
    isValid = (address && address.length) && address.match(pattern)
    return isValid
}